import React from 'react'
import Styled from 'styled-components'
import dayjs from 'dayjs'

type Props = {
  event: Firestore.Event
}

const truncate = (text: string) => {
  return text.length <= 60 ? text : `${text.substr(0, 60)} ...`
}

export default ({ event }: Props) => {
  return (
    <Root>
      <CoverImgWrapper>
        <CoverImg src={event.cover?.source} />
      </CoverImgWrapper>
      <EventDetail>
        <EventDate>{dayjs(event.start_time).format('M月D日')}~</EventDate>
        <EventTitle href={event.permalink_url} target="_blank" rel="noopener noreferrer">
          {event.name}
        </EventTitle>
        <Message>
          {event.description ? truncate(event.description) : ''}
          {'\n'}

          <DetailLink href={event.permalink_url} target="_blank" rel="noopener noreferrer">
            Facebook で詳細を見る
          </DetailLink>

          {event.event_times.length >= 1 && (
            <EventTimes>
              {event.event_times
                .sort(time => time.start_at)
                .reverse()
                .map(eventTime => (
                  <EventTime key={eventTime.id}>
                    {dayjs(eventTime.start_time).format('M月D日 HH:mm')}~
                    {dayjs(eventTime.end_time).format('M月D日 HH:mm')}
                  </EventTime>
                ))}
            </EventTimes>
          )}
        </Message>
      </EventDetail>
    </Root>
  )
}

const Root = Styled.div`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', "hiragino kaku gothic pro",meiryo,"ms pgothic",sans-serif;
  display: flex;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #ddd;
  background: #fff;
  margin-bottom: 0.5rem;
`

const CoverImgWrapper = Styled.div`
  width: 200px;
  height: 200px;
  padding: 1rem 0 1rem 1rem;
`

const CoverImg = Styled.img`
  display: block;
  width: 100%;
`

const EventDate = Styled.div`
  font-weight: bolder;
  color: #e04c60;
  font-size: 0.9rem;
`

const EventDetail = Styled.div`
  padding: 1rem;
  width: 50%;
`

const EventTitle = Styled.a`
  font-size: 1.5rem;
  line-height: 1.4em;
  font-weight: bolder;
  margin-bottom: 0.5rem;
  color: #121212;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const Message = Styled.div`
  font-size: 0.8rem;
  line-height: 1.2em;
  color: #888;
`

const DetailLink = Styled.a`
  display: block;
  color: #385898;
  margin-top: 0.5rem;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const EventTimes = Styled.div`
  margin-top: 1rem;
`

const EventTime = Styled.div`
  margin: 0.5rem 0;

  &:last-child {
    margin: 0;
  }
`
