import React, { useState } from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader, Form, Input, Button, Modal, DatePicker, Upload, Radio } from 'antd'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { RcCustomRequestOptions, UploadFile } from 'antd/lib/upload/interface'
import { firebase } from '@/lib/firebase'
import moment from 'moment'

const { Dragger } = Upload

type Props = {
  type: 'edit' | 'new'
  category: App.Article['category']
  title: string
  onChangeTitle: (s: string) => void
  featuredImageUrl?: string
  contents: App.ArticleContent[]
  onPressAddNewContent: (type: App.ArticleContent['type']) => void
  onPressDeleteContent: (id: App.Article['id']) => void
  onStartUpload: (o: RcCustomRequestOptions) => void
  featuredImageUploadStatus?: UploadFile<any>
  onUpdateFeaturedImageUploadStatus: (staus?: UploadFile<any>) => void
  onUpdateContent: (c: App.ArticleContent) => void
  onPressSubmit: () => void
  isUploadingNewArticle: boolean
  publishedAt?: moment.Moment
  onUpdatePublidhedAt: (date?: moment.Moment) => void
}

const generateTitle = (category: App.Article['category'], type: Props['type']) => {
  const action = type === 'edit' ? '編集' : '新規作成'
  switch (category) {
    case 'startup':
      return `スタートアップ記事を${action}`
  }
}

export default ({
  type,
  category,
  title,
  onChangeTitle,
  featuredImageUrl,
  contents,
  onPressAddNewContent,
  onPressDeleteContent,
  onStartUpload,
  featuredImageUploadStatus,
  onUpdateFeaturedImageUploadStatus,
  onUpdateContent,
  onPressSubmit,
  isUploadingNewArticle,
  onUpdatePublidhedAt,
  publishedAt,
}: Props) => {
  return (
    <Root title={generateTitle(category, type)}>
      <Layout>
        <StyledPageHeader title={generateTitle(category, type)} />
        <PublishedDatePickerWrapper>
          <PublishedDatePickerLabel>公開日時</PublishedDatePickerLabel>
          <div style={{ flex: 1 }} />
          <Radio.Group
            style={{ display: 'flex', flex: 2, alignItems: 'center' }}
            value={!!publishedAt ? 1 : 2}>
            <Radio value={1} onClick={() => onUpdatePublidhedAt(moment())}>
              公開
            </Radio>
            <DatePicker value={publishedAt} showTime onOk={onUpdatePublidhedAt} />
            <div style={{ flex: 1 }} />
            <Radio value={2} onClick={() => onUpdatePublidhedAt()}>
              非公開
            </Radio>
          </Radio.Group>
        </PublishedDatePickerWrapper>
        <Body>
          <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} layout="vertical">
            <Dragger
              style={{ backgroundColor: 'transparent' }}
              name="file"
              multiple={false}
              customRequest={onStartUpload}
              fileList={featuredImageUploadStatus && [featuredImageUploadStatus]}
              onChange={info => {
                const file = info.file
                onUpdateFeaturedImageUploadStatus(file)
              }}>
              <div
                style={{
                  backgroundImage: `url(${featuredImageUrl})`,
                  height: 150,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}>
                <div
                  style={{
                    backgroundColor: 'rgba(255,255,255,0.5)',
                    height: 30,
                    paddingLeft: 30,
                    paddingRight: 30,
                    borderRadius: 15,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  ヘッダー画像をアップロード
                </div>
              </div>
            </Dragger>
            <Input.TextArea
              style={{ borderWidth: 0, fontWeight: 'bold', fontSize: 30, marginTop: 20 }}
              placeholder="記事タイトル"
              autoSize
              value={title}
              onChange={({ target: { value } }) => onChangeTitle(value)}
            />
            {contents.map(c => {
              if (c.type === 'text') {
                return (
                  <div key={c.id} style={{ position: 'relative' }}>
                    <Input.TextArea
                      autoSize
                      style={{ borderWidth: 0, fontWeight: 'bold', fontSize: 16, marginTop: 10 }}
                      placeholder="文章"
                      value={c.text}
                      onChange={({ target: { value } }) =>
                        onUpdateContent({
                          ...c,
                          text: value,
                        })
                      }
                    />
                    <Button
                      shape="circle-outline"
                      style={{ position: 'absolute', top: 10, right: 0 }}
                      onClick={() => onPressDeleteContent(c.id)}>
                      <CloseOutlined />
                    </Button>
                  </div>
                )
              }
              if (c.type === 'title') {
                return (
                  <div key={c.id} style={{ position: 'relative' }}>
                    <Input.TextArea
                      autoSize
                      value={c.text}
                      style={{ borderWidth: 0, fontWeight: 'bold', fontSize: 22, marginTop: 20 }}
                      placeholder="見出し"
                      onChange={({ target: { value } }) =>
                        onUpdateContent({
                          ...c,
                          text: value,
                        })
                      }
                    />
                    <Button
                      shape="circle-outline"
                      style={{ position: 'absolute', top: 10, right: 0 }}
                      onClick={() => onPressDeleteContent(c.id)}>
                      <CloseOutlined />
                    </Button>
                  </div>
                )
              }
              if (c.type === 'images') {
                return (
                  <div key={c.id} style={{ position: 'relative', marginLeft: 10, marginTop: 14 }}>
                    <div
                      style={{
                        color: 'rgba(0,0,0,0.3)',
                        fontWeight: 'bold',
                        marginBottom: 6,
                      }}>
                      画像（最大5枚）
                    </div>
                    <UploadButton
                      key={c.id}
                      uploadedUrls={c.urls}
                      onComplete={urls =>
                        onUpdateContent({
                          ...c,
                          urls,
                        })
                      }
                    />
                    <Button
                      shape="circle-outline"
                      style={{ position: 'absolute', top: 10, right: 0 }}
                      onClick={() => onPressDeleteContent(c.id)}>
                      <CloseOutlined />
                    </Button>
                  </div>
                )
              }
              return null
            })}
            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 30 }}>
              <Button
                type="dashed"
                shape="round"
                style={{ height: 36, marginRight: 10, marginBottom: 10 }}
                icon={<PlusOutlined />}
                onClick={() => onPressAddNewContent('title')}>
                見出し
              </Button>
              <Button
                type="dashed"
                shape="round"
                icon={<PlusOutlined />}
                style={{ height: 36, marginRight: 10, marginBottom: 10 }}
                onClick={() => onPressAddNewContent('text')}>
                文章
              </Button>
              <Button
                type="dashed"
                icon={<PlusOutlined />}
                shape="round"
                style={{ height: 36, marginRight: 10, marginBottom: 10 }}
                onClick={() => onPressAddNewContent('images')}>
                画像
              </Button>
              {/* <Button
                type="dashed"
                shape="round"
                icon={<PlusOutlined />}
                style={{ height: 36, marginRight: 10, marginBottom: 10 }}
                onClick={() => onPressAddNewContent('video')}>
                動画
              </Button> */}
            </div>
          </Form>
        </Body>
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
            marginTop: 16,
            backgroundColor: 'white',
            padding: 20,
          }}>
          <Button
            loading={isUploadingNewArticle}
            type="primary"
            shape="round"
            style={{ width: 200, height: 40 }}
            onClick={onPressSubmit}>
            {type === 'edit' ? '変更を保存' : '新規作成'}
          </Button>
        </div>
      </Layout>
    </Root>
  )
}

const UploadButton = ({
  onComplete,
  uploadedUrls,
}: {
  uploadedUrls: string[]
  onComplete: (urls: string[]) => void
}) => {
  const [currentUploadFile, updateCurrentUpladFile] = useState<UploadFile | undefined>()
  const [previewImageUrl, setPreviewImageUrl] = useState<string | undefined>()
  const [isUploading, setIsUploading] = useState(false)
  const uploadedFileList: UploadFile<any>[] = uploadedUrls.map(url => ({
    uid: url,
    size: 0,
    name: url,
    percent: 0,
    type: '',
    status: 'success',
    url,
  }))
  return (
    <div>
      <Upload
        listType="picture-card"
        customRequest={({ file, onProgress }) => {
          const storageRef = firebase.storage().ref()
          const name = new Date().toISOString() + '.jpg'
          const uploadTask = storageRef
            .child('articles')
            .child(name)
            .put(file, { contentType: 'image/jpeg' })
          const tmpFile: UploadFile = {
            uid: name,
            size: 0,
            name,
            percent: 0,
            type: '',
            status: 'uploading',
          }
          setIsUploading(true)
          uploadTask.on(
            'state_changed',
            snapshot => {
              const percent = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              onProgress({ percent }, file)
              updateCurrentUpladFile({ ...tmpFile, percent })
            },
            () => {
              updateCurrentUpladFile(undefined)
            },
            () => {
              uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                updateCurrentUpladFile(undefined)
                onComplete([...uploadedUrls, downloadURL])
              })
              setIsUploading(false)
            },
          )
        }}
        fileList={currentUploadFile ? [...uploadedFileList, currentUploadFile] : uploadedFileList}
        onPreview={file => setPreviewImageUrl(file.url || file.preview)}>
        {uploadedUrls.length >= 5 || isUploading ? null : (
          <div>
            <PlusOutlined />
            <div className="ant-upload-text">Upload</div>
          </div>
        )}
      </Upload>
      <Modal visible={!!previewImageUrl} footer={null} onCancel={() => setPreviewImageUrl(undefined)}>
        <img alt="example" style={{ width: '100%' }} src={previewImageUrl} />
      </Modal>
    </div>
  )
}

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const Body = styled.div`
  background-color: white;
  padding: 16px;
`

const PublishedDatePickerWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: white;
  padding: 16px;
  margin-bottom: 2px;
`

const PublishedDatePickerLabel = styled.div`
  font-weight: bold;
`
