import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'

const collectionRef = firestore.collection('checkin_logs')

export const useCheckinLogs = () => {
  const [checkinLogs, setCheckinLogs] = useState<Firestore.CheckinLog[]>([])

  useEffect(() => {
    return collectionRef
      .orderBy('checkin_at', 'desc')
      .limit(100)
      .onSnapshot(async a => {
        const p = await Promise.all(
          a.docs.map<Promise<Firestore.CheckinLog>>(async b => {
            const data = b.data()
            return {
              ...data,
              id: b.id,
            } as Firestore.CheckinLog
          }),
        )
        setCheckinLogs(p)
      })
  }, [])

  return { checkinLogs }
}
