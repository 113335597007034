import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'

type Props = {
  userId: Firestore.User['id']
}

export const useUser = (props: Props) => {
  const [user, setUser] = useState<Firestore.User>()
  const [userRewards, setUserRewards] = useState<Firestore.UserReward[]>([])

  useEffect(() => {
    let checkinLogsArray: Firestore.CheckinLog[] = []
    let userRewardsArray: Firestore.UserReward[] = []
    const fetchUser = async () => {
      const userDoc = await firestore.collection('users').doc(props.userId).get()
      const userData = userDoc.data()

      firestore.collection('checkin_logs')
        .where('user_uid', '==', props.userId)
        .orderBy('checkin_at', 'desc')
        .limit(100)
        .onSnapshot(async a => {
            a.docs
            .map(async b => {
              const data = b.data()
              const checkinLog = {
                ...data,
                id: b.id,
              } as Firestore.CheckinLog
              checkinLogsArray = [...checkinLogsArray, checkinLog]
              setUser({
                ...userData,
                checkinLogs: checkinLogsArray,
                id: props.userId
              } as Firestore.User)
              })
          })

      firestore
        .collectionGroup('userRewards')
        .where('userId', '==', props.userId)
        .orderBy('createdAt', 'desc')
        .limit(20)
        .get().then(async a => {
          a.docs.map(async b => {
            const data = b.data()
            const userReward = {
              ...data,
              id: b.id,
            } as Firestore.UserReward
            userRewardsArray = [...userRewardsArray, userReward]
            setUserRewards(userRewardsArray)
          })
        })
    }
    props.userId && fetchUser()
  }, [props.userId])

  return { user, userRewards }
}
