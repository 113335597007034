import { useCheckinLogs } from '@/hooks/useCheckinLogs'
import { Layout } from './Layout'
import { useHistory } from 'react-router-dom'

export const useProps = (): FCProps<typeof Layout> => {
  const { checkinLogs } = useCheckinLogs()
  const history = useHistory()

  return {
    checkinLogs,
    onPressShow: async id => {
      history.push(`/users/${id}`)
    },
  }
}
