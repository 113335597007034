import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { LayoutProps } from './props'
import { Layout, PageHeader, Input, Button } from 'antd'
const { TextArea } = Input

const pageTitle = 'ロイヤリティプログラム'

export default (props: LayoutProps) => (
  <Root title={pageTitle}>
    <Layout>
      <StyledPageHeader title={pageTitle} />
      <ContentBody>
        <Description>ロイヤリティプログラムに関する情報を編集できます</Description>
        {props.visibleTextForm ? (
          <>
            <TextArea rows={6} value={props.formText} onChange={(e) => props.onChangeText(e.target.value)}></TextArea>
            <Button onClick={props.onPressCancelText}>キャンセル</Button>
            <Button type="primary" onClick={props.onSubmitText}>変更</Button>
          </>
        ) : (
          <>
            {props.formText.split('\n').map((str, index) => (
              <React.Fragment key={index}>{str}<br /></React.Fragment>
            ))}
            <Button onClick={props.onPressEditText}>編集</Button>
          </>
        )}
      </ContentBody>
    </Layout>
  </Root>
)

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
  position: relative;
`

const Description = styled.div`
  font-size: 14px;
  color: #aaa;
  margin-bottom: 20px;
`