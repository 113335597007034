import { useArticles } from '@/hooks/useArticls'
import { Layout } from './Layout'
import { useState } from 'react'
import { useFullScreenLoading } from '@/context/FullScreenLoadingContext'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'

type Params = {
  category: App.Article['category']
}

export const useProps = ({ category }: Params): FCProps<typeof Layout> => {
  const { articles, deleteArticle } = useArticles(category)
  const [newArticleLink] = useState(() => {
    switch (category) {
      case 'startup':
        return '/startups/new'
      default:
        return ''
    }
  })
  const { setVisible } = useFullScreenLoading()
  const history = useHistory()
  return {
    articles,
    newArticleLink,
    onPressDelete: async id => {
      if (window.confirm('本当に削除しますか？')) {
        setVisible(true)
        try {
          await deleteArticle({ id })
          message.success('削除に成功しました')
        } catch (e) {
          message.error('削除に失敗しました')
        } finally {
          setVisible(false)
        }
      }
    },
    onPressEdit: async id => {
      history.push(`${history.location.pathname}/${id}/edit`)
    },
  }
}
