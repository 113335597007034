import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { LayoutProps } from './props'
import { Layout, Menu } from 'antd'
import {
  LineChartOutlined,
  CheckOutlined,
  FireOutlined,
  FacebookOutlined,
  EditOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  PayCircleOutlined,
} from '@ant-design/icons'
import 'antd/dist/antd.css'

const { Header, Content, Footer, Sider } = Layout

export default ({ selectedKey, logout, children }: LayoutProps) => (
  <RootLayout>
    <Sider collapsible>
      <Logo></Logo>
      <Menu theme="dark" defaultSelectedKeys={[selectedKey]} defaultOpenKeys={['posts']} mode="inline">
        <Menu.Item key="dashboard">
          <Link to="/">
            <LineChartOutlined />
            <span>ダッシュボード</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="notifications">
          <Link to="/checkin_logs">
            <CheckOutlined />
            <span>来館履歴</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="posts">
          <Link to="/posts">
            <FireOutlined />
            <span>新着情報</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="events">
          <Link to="/events">
            <FacebookOutlined />
            <span>イベント</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="users">
          <Link to="/users">
            <UserOutlined />
            <span>登録ユーザー</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="startups">
          <Link to="/startups">
            <EditOutlined />
            <span>スタートアップ記事</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="information">
          <Link to="/information">
            <ExclamationCircleOutlined />
            <span>インフォメーション</span>
          </Link>
        </Menu.Item>
        <Menu.Item key="loyalties">
          <Link to="/loyalties">
            <PayCircleOutlined />
            <span>ロイヤリティ</span>
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
    <Layout>
      <StyledHeader>
        <Menu mode="horizontal">
          <Menu.Item>
            <LogoutLink onClick={logout}>ログアウト</LogoutLink>
          </Menu.Item>
        </Menu>
      </StyledHeader>
      <ChildContent>{children}</ChildContent>
      <StyledFooter>
        Created by <a href="https://www.appabrik.com/ja">Appabrik</a>
      </StyledFooter>
    </Layout>
  </RootLayout>
)

const RootLayout = styled(Layout)`
  min-height: 100vh;
`

const Logo = styled.div`
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
`

const StyledHeader = styled(Header)`
  padding: 0;
  background: #fff;
  text-align: right;
`

const LogoutLink = styled.div`
  cursor: pointer;
  transition: opacity 0.25s ease;
  &:hover {
    opacity: 0.7;
  }
`

const ChildContent = styled(Content)`
  margin: 0 16px;
`

const StyledFooter = styled(Footer)`
  text-align: center;
`
