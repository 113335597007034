import { Api } from '@/lib/api'
import { Loyalty } from '@/lib/loyalty'
import { useState, useEffect } from 'react'
import { message } from 'antd'
import { LayoutProps } from './props'

export default (): LayoutProps => {
  const [formText, setFormText] = useState<string>('')
  const [baseText, setBaseText] = useState<string>('')
  const [visibleTextForm, setVisibleTextForm] = useState<boolean>(false)

  useEffect(() => {
    const getDescriptions = async () => {
      const descriptions = await Loyalty.getDescriptions()
      setBaseText(descriptions)
      setFormText(descriptions)
    }
    getDescriptions()
  }, [])

  return {
    formText,
    visibleTextForm,
    onPressEditText: () => setVisibleTextForm(true),
    onChangeText: (text: string) => setFormText(text),
    onPressCancelText: () => {
      setFormText(baseText)
      setVisibleTextForm(false)
    },
    onSubmitText: () => {
      Api.putLoyaltySettings({ descriptions: formText })
      setBaseText(formText)
      setVisibleTextForm(false)
      message.success({
        content: '変更に成功しました。',
      })
    },
  }
}
