import { firestore } from './firebase'

export const Api = {
  getArticle: (params: { id: App.Article['id'] }): Promise<App.Article> =>
    firestore
      .collection('articles')
      .doc(params.id)
      .get()
      .then(doc => ({ id: params.id, ...doc.data() })) as any,

  putLoyaltySettings: async (params: Firestore.LoyaltySetting) => {
    await firestore
      .collection('settings')
      .doc('loyalties')
      .set(params, { merge: true })
  },

  putUser: async (params: { user: Pick<Firestore.User, 'id' | 'note'> }): Promise<boolean> => {
    if (!params.user || !params.user.id) return false
    try {
      await firestore
        .collection('users')
        .doc(params.user.id)
        .set(params.user, { merge: true })
      return true
    } catch {
      return false
    }
  },

  deletePost: async (params: { id: Firestore.Post['id'] }): Promise<boolean> => {
    if (!params.id) return false
    try {
      await firestore
        .collection('posts')
        .doc(params.id)
        .delete()
      return true
    } catch (e) {
      return false
    }
  },
}
