import React from "react";
import styled from 'styled-components'
import { WithAuth as Root } from "@/components/Root";
import { Layout, PageHeader, Descriptions, Divider, Timeline, Button, Input } from 'antd'
import { Loyalty } from '@/lib/loyalty'
const { TextArea } = Input

const pageTitle = '利用者詳細'

type Props = {
  user?: Firestore.User
  userRewards: Firestore.UserReward[]
  loyalties: Firestore.Loyalty[]
  onPressLoyalty: (loyalty: Firestore.Loyalty) => void
  visibleRewardForm: boolean
  onPressVisibleReward: () => void
  onChangeRewardTitle: (title: Firestore.UserReward['title']) => void
  onChangeRewardPoint: (point: Firestore.UserReward['point']) => void
  onPressCancelReward: () => void
  onSubmitReward: () => void
  formText: string
  visibleTextForm: boolean
  onPressEditText: () => void
  onChangeText: (text: string) => void
  onPressCancelText: () => void
  onSubmitText: () => void
}

export default (props: Props) => {
  return (
    <Root title={pageTitle}>
      <Layout>
        <StyledPageHeader title={pageTitle} />
        <ContentBody>
          <Descriptions bordered>
            <Descriptions.Item label="ユーザーID" span={4}>
              {props.user?.id}
            </Descriptions.Item>
            <Descriptions.Item label="名前" span={4}>
              {props.user?.name}
            </Descriptions.Item>
            <Descriptions.Item label="会社名" span={4}>
              {props.user?.company_name}
            </Descriptions.Item>
            <Descriptions.Item label="メールアドレス" span={4}>
              {props.user?.email_address}
            </Descriptions.Item>
            <Descriptions.Item label="会員ステータス" span={4}>
              {Loyalty.getStatusLabel(props.user?.loyaltyStatus)}
            </Descriptions.Item>
            <Descriptions.Item label="ギャラステ" span={4}>
              {props.user?.totalLoyaltyPoint ?? 0} G
            </Descriptions.Item>
            <Descriptions.Item label="メモ" span={4}>
              {props.visibleTextForm ? (
                <>
                  <TextArea rows={6} value={props.formText} onChange={(e) => props.onChangeText(e.target.value)}></TextArea>
                  <Button onClick={props.onPressCancelText}>キャンセル</Button>
                  <Button type="primary" onClick={props.onSubmitText}>変更</Button>
                </>
              ) : (
                <>
                  {props.formText && props.formText.split('\n').map((str, index) => (
                    <React.Fragment key={index}>{str}<br /></React.Fragment>
                  ))}
                  <Button onClick={props.onPressEditText}>編集</Button>
                </>
              )}
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">ロイヤリティ</Divider>
            {props.visibleRewardForm ? (
              <>
                <span>
                  <Input type="text" placeholder="付与する理由" onChange={(e) => props.onChangeRewardTitle(e.target.value)} />
                  <Input type="number" placeholder="付与するポイント" onChange={(e) => props.onChangeRewardPoint(e.target.valueAsNumber)} />
                  <Button onClick={props.onPressCancelReward}>キャンセル</Button>
                  <Button type="primary" onClick={props.onSubmitReward}>付与</Button>
                </span>
              </>
            ) : (
              <>
                {props.loyalties.map((loyalty) => (
                  <Button onClick={() => props.onPressLoyalty(loyalty)}>{loyalty.title}</Button>
                ))}
                <Button style={{marginLeft: 24}} onClick={() => props.onPressVisibleReward()}>手動で付与</Button>
              </>
            )}
          <Timeline style={{marginTop: 24}}>
            {props.userRewards.map((userReward) => (
              <Timeline.Item>{`${userReward.createdAt.toDate().toLocaleString('ja-JP')}: ${userReward.title} で ${userReward.point} Gのボーナス`}</Timeline.Item>
            ))}
          </Timeline>
          <Divider orientation="left">来館履歴</Divider>
          <Timeline>
            {props.user?.checkinLogs.map((checkinLog) => (
              <Timeline.Item>{checkinLog.checkin_at.toDate().toLocaleString('ja-JP')}</Timeline.Item>
            ))}
          </Timeline>
        </ContentBody>
      </Layout>
    </Root>
  )
}

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
