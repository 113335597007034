import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getEvents, getWeeklyEvents, refreshEvents } from "@/lib/request";
import { LayoutProps } from "./props";

export default (): LayoutProps => {
  const idToken = useSelector<RootReducer, string | null>(
    ({ idToken }) => idToken
  );
  const [events, setEvents] = useState<Firestore.Event[]>([]);
  const [thisWeekEvents, setThisWeekEvents] = useState<Firestore.WeeklyEvent[]>(
    []
  );
  const [nextWeekEvents, setNextWeekEvents] = useState<Firestore.WeeklyEvent[]>(
    []
  );
  const [afterNextWeekEvents, setAfterNextWeekEvents] = useState<
    Firestore.WeeklyEvent[]
  >([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const reloadEvents = async (token: string) => {
    setLoading(true);
    const result = await getEvents(token);
    if (result.type === "Failure") return;
    setEvents(result.response || []);

    const weeklyEventsResult = await getWeeklyEvents(token);
    if (weeklyEventsResult.type === "Failure") return;

    const {
      this_week,
      next_week,
      after_next_week
    } = weeklyEventsResult.response;

    setThisWeekEvents(this_week);
    setNextWeekEvents(next_week);
    setAfterNextWeekEvents(after_next_week);
    setLoading(false);
  };

  const refresh = async () => {
    if (!idToken) return;
    setRefreshing(true);
    await refreshEvents(idToken);
    await reloadEvents(idToken);
    setRefreshing(false);
  };

  useEffect(() => {
    idToken && reloadEvents(idToken);
  }, [idToken]);

  return {
    loading,
    refreshing,
    events,
    thisWeekEvents,
    nextWeekEvents,
    afterNextWeekEvents,
    refresh
  };
};
