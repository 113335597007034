import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getPosts, refreshPosts } from "@/lib/request";
import { LayoutProps } from "./props";

export default (): LayoutProps => {
  const idToken = useSelector<RootReducer, string | null>(
    ({ idToken }) => idToken
  );
  const [posts, setPosts] = useState<Firestore.Post[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const reloadPosts = async (token: string) => {
    setLoading(true);
    const result = await getPosts(token);
    if (result.type === "Failure") return;

    setPosts(result.response || []);
    setLoading(false);
  };

  const refresh = async () => {
    if (!idToken) return;
    setRefreshing(true);
    await refreshPosts(idToken);
    await reloadPosts(idToken);
    setRefreshing(false);
  };

  useEffect(() => {
    idToken && reloadPosts(idToken);
  }, [idToken]);

  return {
    posts,
    refresh,
    loading,
    refreshing
  };
};
