import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { RoundedBorderButton } from '@/components/Button'
import FacebookPost from '@/components/FacebookPost'
import Loading from '@/components/Loading'
import { LayoutProps } from './props'
import { Layout, PageHeader } from 'antd'

const pageTitle = '新着情報'

export default ({ loading, refreshing, refresh, posts }: LayoutProps) => (
  <Root title={pageTitle}>
    <Layout>
      <StyledPageHeader title={pageTitle} />

      <ContentBody>
        <Button onClick={refresh}>{refreshing ? '...' : 'Facebook から最新情報を取得'}</Button>

        {!loading && posts.map(post => <FacebookPost key={post.id} post={post} />)}

        <Loading show={loading} />
      </ContentBody>
    </Layout>
  </Root>
)

const Button = styled(RoundedBorderButton)`
  margin: 0 0 2rem auto;
`

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
