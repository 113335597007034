import React, { useState, useEffect } from 'react'
import Layout from './Layout'
import { useProps } from './useProps'
import { Api } from '@/lib/api'

type Props = {
  category?: App.Article['category']
  articleId?: App.Article['id']
}

export const EditArticle = (params: Props) => {
  const [initial, setInitial] = useState<{
    id?: App.Article['id']
    title?: App.Article['title']
    category: App.Article['category']
    featuredImageUrl?: string
    contents?: App.Article['contents']
    publishedAt?: App.Article['publishedAt']
  }>({ category: params.category ?? 'startup', id: params.articleId })
  useEffect(() => {
    params.articleId &&
      Api.getArticle({ id: params.articleId }).then(article => {
        setInitial({
          category: article.category,
          contents: article?.contents,
          featuredImageUrl: article?.featuredImageUrl,
          id: article?.id,
          title: article?.title,
          publishedAt: article.publishedAt,
        })
      })
  }, [])
  return <Layout {...useProps({ initial })} />
}
