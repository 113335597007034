import { useState, useEffect } from 'react'
import { firestore } from '@/lib/firebase'

const collectionRef = firestore.collection('loyalties')

export const useLoyalties = () => {
  const [loyalties, setLoyalties] = useState<Firestore.Loyalty[]>([])
  useEffect(() => {
    return collectionRef
      .onSnapshot(async a => {
        const p = await Promise.all(
          a.docs.map<Promise<Firestore.Loyalty>>(async b => {
            const data = b.data()
            return {
              ...data,
              id: b.id,
            } as Firestore.Loyalty
          }),
        )
        setLoyalties(p)
      })
  }, [])

  return { loyalties }
}
