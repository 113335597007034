import qs from 'qs'
import { Moment } from 'moment'

export type ApiResult<T = any> = ApiSuccessResult<T> | ApiFailureResult

type ApiSuccessResult<T> = {
  type: 'Success'
  response: T
  rawResponse: Response
}

type ApiFailureResult = {
  type: 'Failure'
  rawResponse: Response
}

type RequestOptions = {
  idToken: string
  params?: { [key: string]: any }
}

const baseUrl = `https://us-central1-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`
const makeUrl = (endpoint: string) => {
  return `${baseUrl}/${endpoint}`
}

const makeHeaders = (idToken: string) => ({
  'Content-Type': 'application/json',
  Authorization: `Bearer ${idToken}`,
})

export const getProfile = async (idToken: string) => {
  return get('console/profile', { idToken })
}

export const getPosts = async (idToken: string) => {
  return get<Firestore.Post[]>('console/posts', { idToken })
}

export const getEvents = async (idToken: string) => {
  return get<Firestore.Event[]>('console/events', { idToken })
}

export const getWeeklyEvents = async (idToken: string) => {
  return get<{
    this_week: Firestore.WeeklyEvent[]
    next_week: Firestore.WeeklyEvent[]
    after_next_week: Firestore.WeeklyEvent[]
  }>('console/weekly_events', { idToken })
}

export const getUsers = async (idToken: string, page: string | null) => {
  return get<Firestore.User[]>('console/users', {
    idToken,
    params: { page },
  })
}

export const getUser = async (idToken: string, id: string) => {
  return get<Firestore.User>(`console/users/${id}`, { idToken })
}

export const getCheckinLogs = async (idToken: string, page: string | null) => {
  return get<Firestore.CheckinLog[]>('console/checkin_logs', {
    idToken,
    params: { page },
  })
}

export const refreshEvents = async (idToken: string) => {
  return post<any>('console/events/refresh', { idToken })
}

export const refreshPosts = async (idToken: string) => {
  return post<any>('console/posts/refresh', { idToken })
}

const get = async <T>(endpoint: string, { idToken, params }: RequestOptions) => {
  const path = `${endpoint}?${qs.stringify(params, {
    arrayFormat: 'brackets',
  })}`

  return request<T>(path, {
    method: 'GET',
    headers: makeHeaders(idToken),
  })
}

const post = async <T>(endpoint: string, { idToken }: RequestOptions) => {
  return request<T>(endpoint, {
    method: 'POST',
    headers: makeHeaders(idToken),
  })
}

const request = async <T>(endpoint: string, options: RequestInit): Promise<ApiResult<T>> => {
  const response = await fetch(makeUrl(endpoint), options)

  const parsedResponse = await response.json()

  if (response.status === 200) {
    return {
      type: 'Success',
      response: parsedResponse.data,
      rawResponse: response,
    }
  }

  return {
    type: 'Failure',
    rawResponse: response,
  }
}

const postRequest = async <T extends {}>(endPoint: string, idToken: string, payload: any) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'POST',
    headers: makeHeaders(idToken),
    body: JSON.stringify(payload),
  })
  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json() as Promise<T>
}

const putRequest = async (endPoint: string, idToken: string, payload: any) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'PUT',
    headers: makeHeaders(idToken),
    body: JSON.stringify(payload),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json()
}

const deleteRequest = async (endPoint: string, idToken: string) => {
  const response = await fetch(makeUrl(endPoint), {
    method: 'DELETE',
    headers: makeHeaders(idToken),
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.json()
}

export const ArticleRequest = {
  post: async (
    idToken: string,
    params: {
      featuredImageUrls: string[]
      title: string
      category: App.Category
      contents: App.ArticleContent[]
      publishedAt?: Moment
    },
  ) => {
    return postRequest<{
      article: Pick<
        App.Article,
        'sourceUrl' | 'featuredImageUrl' | 'featuredImageUrls' | 'title' | 'contents' | 'category'
      >
    }>('console/articles', idToken, {
      article: {
        category: params.category,
        contents: params.contents,
        sourceUrl: '',
        title: params.title,
        featuredImageUrls: params.featuredImageUrls,
        publishedAtMillis: params.publishedAt?.toDate().getTime(),
      },
    })
  },
  delete: async (idToken: string, params: { id: App.Article['id'] }) => {
    return deleteRequest(`console/articles/${params.id}`, idToken)
  },
  update: async (
    idToken: string,
    params: {
      id: string
      featuredImageUrls: string[]
      title: string
      category: App.Category
      contents: App.ArticleContent[]
      publishedAt?: Moment
    },
  ) => {
    return putRequest(`console/articles/${params.id}`, idToken, {
      article: {
        id: params.id,
        category: params.category,
        contents: params.contents,
        sourceUrl: '',
        title: params.title,
        featuredImageUrls: params.featuredImageUrls,
        publishedAtMillis: params.publishedAt?.toDate().getTime(),
      },
    })
  },
}
