import { useCallback, useEffect, useState } from 'react'
import firebase from '@/lib/firebase'

type State = {
  loading: boolean
  informations: {
    campaign?: {
      image: string
      link: string
    }
    kirameki?: {
      image: string
      link: string
    }
    lounge_information?: {
      image: string
      link: string
    }
  }
  upload: (params: { key: string; url: string; image: File }) => Promise<boolean>
}

const avaiableDocIds: Array<keyof State['informations']> = ['campaign', 'kirameki', 'lounge_information']

export const useInformations = (): State => {
  const [loading, setLoading] = useState(false)
  const [informations, setInformations] = useState<State['informations']>({})

  const fetchInformations = useCallback(() => {
    setLoading(true)
    firebase
      .firestore()
      .collection('informations')
      .get()
      .then(ss => {
        const newInformations: State['informations'] = ss.docs
          .filter(doc => doc.exists && avaiableDocIds.find(k => k === doc.id))
          .map(doc => {
            return {
              [doc.id]: doc.data(),
            }
          })
          .reduce((prev, current) => {
            return {
              ...prev,
              ...current,
            }
          }, {})
        setInformations(newInformations)
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(fetchInformations, [fetchInformations])

  return {
    loading,
    informations,
    upload: async ({ key, url, image }): Promise<boolean> => {
      const ref = `/informations/${key}_${image.name}`
      try {
        await firebase
          .storage()
          .ref(ref)
          .put(image)
        const imageUrl = await firebase
          .storage()
          .ref(ref)
          .getDownloadURL()

        await firebase
          .firestore()
          .collection('informations')
          .doc(key)
          .set({
            image: imageUrl,
            link: url,
          })
        fetchInformations()
        return true
      } catch (e) {
        console.log(e)
        return false
      }
    },
  }
}
