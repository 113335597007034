import React from 'react'
import Styled from 'styled-components'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { Button, Space, message } from 'antd'
import { Api } from '@/lib/api'

dayjs.extend(relativeTime)

type Props = {
  post: Firestore.Post
}

const truncate = (text: string) => {
  return text.length <= 100 ? text : `${text.substr(0, 100)} ...`
}

export default ({ post }: Props) => {
  const now = dayjs()

  return (
    <Root>
      <Profile>
        <ProfileImg src={`${process.env.PUBLIC_URL}/snsicon.png`} />
        <div>
          <ProfileName href="https://www.facebook.com/garrawayf/" target="_blank" rel="noopener noreferrer">
            GarrawayF
          </ProfileName>
          <UpdatedTime href={post.permalink_url} target="_blank" rel="noopener noreferrer">
            {dayjs(post.updated_time).from(now)}
          </UpdatedTime>
        </div>
      </Profile>
      <Message>
        {truncate(post.message)}
        {'\n'}
        <StyledSpace>
          <DetailLink href={post.permalink_url} target="_blank" rel="noopener noreferrer">
            <Button>Facebook で詳細を見る</Button>
          </DetailLink>
          <Button danger onClick={() => onPressDelete(post.id)}>
            削除
          </Button>
        </StyledSpace>
      </Message>
    </Root>
  )
}

const onPressDelete = async (id: Firestore.Post['id']) => {
  if (window.confirm('本当に削除しますか？')) {
    const response = await Api.deletePost({ id })
    if (!response) {
      message.error('削除に失敗しました')
      return
    }
    message.success('削除に成功しました')
  }
}

const Root = Styled.div`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, '.SFNSText-Regular', "hiragino kaku gothic pro",meiryo,"ms pgothic",sans-serif;
  border-radius: 2px;
  border: 1px solid #ddd;
  background: #fff;
  margin-bottom: 0.5rem;
`

const Message = Styled.div`
  white-space: pre-wrap;
  font-size: 0.9rem;
  line-height: 1.2em;
  padding: 1rem;
`

const Profile = Styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0;
`

const ProfileImg = Styled.img`
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin-right: 0.5rem;
`

const ProfileName = Styled.a`
  font-weight: bolder;
  font-size: 0.85rem;
  color: #385898;
  display: block;
  text-decoration: none;
  line-height: 1.8em;

  &:hover {
    text-decoration: underline;
  }
`

const UpdatedTime = Styled.a`
  display: block;
  font-size: 0.8rem;
  color: #666;
  text-decoration: none;
  line-height: 1em;

  &:hover {
    text-decoration: underline;
  }
`

const DetailLink = Styled.a`
  display: inline-block;
`

const StyledSpace = Styled(Space)`
  margin-top: 1em;
`
