import React from 'react'
import { createRouter } from './createRouter'
import Dashboard from '@/pages/dashboard'
import CheckinLogs from '@/pages/checkin_logs'
import LoginPage from '@/pages/login'
import PostsPage from '@/pages/posts'
import EventsPage from '@/pages/events'
import UsersPage from '@/pages/users'
import InformaitonPage from '@/pages/information'
import Loyalties from '@/pages/loyalties'
import { User } from '@/pages/users/detail'
import Startups from '@/pages/articles/startups'
import { EditArticle } from '@/pages/articles/edit'

export const Routes: CustomRoute[] = [
  {
    needAuth: false,
    path: '/login',
    component: LoginPage,
  },
  {
    needAuth: true,
    path: '/posts',
    component: PostsPage,
  },
  {
    needAuth: true,
    path: '/events',
    component: EventsPage,
  },
  {
    needAuth: true,
    path: '/users/:id',
    component: ({ params }) => {
      return <User userId={params.id} />
    },
  },
  {
    needAuth: true,
    path: '/users',
    component: UsersPage,
  },
  {
    needAuth: true,
    path: '/startups',
    component: Startups,
  },
  {
    needAuth: true,
    path: '/startups/new',
    component: () => <EditArticle category="startup" />,
  },
  {
    needAuth: true,
    path: '/startups/:id/edit',
    component: ({ params }) => {
      return <EditArticle category="startup" articleId={params.id} />
    },
  },
  {
    needAuth: true,
    path: '/checkin_logs',
    component: CheckinLogs,
  },
  {
    needAuth: true,
    path: '/information',
    component: InformaitonPage,
  },
  {
    needAuth: true,
    path: '/loyalties',
    component: Loyalties,
  },
  {
    needAuth: true,
    path: '/',
    component: Dashboard,
  },
]

export const Router = createRouter(Routes)
