import { firestore } from './firebase'
import Firebase from 'firebase/app'
import dayjs from 'dayjs'

const THRESHOLD1 = 3000
const THRESHOLD2 = 5000
const THRESHOLD3 = 1000000

const LOYALTY_STATUS1 = 'loyaltyStatus1'
const LOYALTY_STATUS2 = 'loyaltyStatus2'
const LOYALTY_STATUS3 = 'loyaltyStatus3'
const LOYALTY_STATUS4 = 'loyaltyStatus4'

export const Loyalty = {
  addReward: async (params: { userId: App.User['id'], loyaltyId: Firestore.Loyalty['id'] }): Promise<boolean> => {
    // Get a target loyalty
    const loyaltyDoc = await firestore
      .collection('loyalties')
      .doc(params.loyaltyId)
      .get()
    if (!loyaltyDoc.exists) return false
    const loyalty = loyaltyDoc.data() as Firestore.Loyalty
    if (!loyalty) return false

    // Get a target user
    const userSnapshot = firestore
      .collection('users')
      .doc(params.userId) as Firebase.firestore.DocumentReference<Firestore.User>
    const userDoc = await userSnapshot.get()
    if (!userDoc.exists) return false
    const user = userDoc.data() as Firestore.User
    if (!user) return false

    // confirm loyalty type
    if (loyalty.type && loyalty.type === 'oncePerDay') {
      const startOfToday = Firebase.firestore.Timestamp.fromDate(dayjs().startOf('day').toDate())
      try {
        const rewardsSnapshot = await firestore
          .collectionGroup('userRewards')
          .where('userId', '==', params.userId)
          .where('createdAt', '>=', startOfToday)
          .get()
        if (rewardsSnapshot.docs.filter(doc => {
          const data = doc.data()
          return data.key === loyalty.key
        }).length > 0) return false
      } catch (e) {
        return false
      }
    }

    // Add a reward
    const totalLoyaltyPoint = (user.totalLoyaltyPoint ?? 0) + loyalty.point
    const userReward: Firestore.UserReward = {
      userRef: userSnapshot,
      userId: params.userId,
      key: loyalty.key,
      title: loyalty.title,
      point: loyalty.point,
      createdAt: Firebase.firestore.Timestamp.now()
    }
    try {
      await firestore
        .collection('users')
        .doc(params.userId)
        .collection('userRewards')
        .add(userReward)
      await firestore
        .collection('users')
        .doc(params.userId)
        .set({ totalLoyaltyPoint, loyaltyStatus: Loyalty.getStatus(totalLoyaltyPoint) }, { merge: true })
    } catch (e) {
      return false
    }
    return true
  },

  getStatus: (totalLoyaltyPoint: Firestore.User['totalLoyaltyPoint']): Firestore.LoyaltyStatus => {
    if (!totalLoyaltyPoint || totalLoyaltyPoint < THRESHOLD1) {
      return LOYALTY_STATUS1
    } else if (THRESHOLD1 <= totalLoyaltyPoint && totalLoyaltyPoint < THRESHOLD2) {
      return LOYALTY_STATUS2
    } else if (THRESHOLD2 <= totalLoyaltyPoint && totalLoyaltyPoint < THRESHOLD3) {
      return LOYALTY_STATUS3
    } else if (THRESHOLD3 <= totalLoyaltyPoint) {
      return LOYALTY_STATUS4
    } else {
      return LOYALTY_STATUS1
    }
  },

  addRewardManually: async (params: { userId: App.User['id'], rewardTitle: Firestore.UserReward['title'], rewardPoint: Firestore.UserReward['point'] }): Promise<boolean> => {
    // Get a target user
    const userSnapshot = firestore
      .collection('users')
      .doc(params.userId) as Firebase.firestore.DocumentReference<Firestore.User>
    const userDoc = await userSnapshot.get()
    if (!userDoc.exists) return false
    const user = userDoc.data() as Firestore.User
    if (!user) return false

    // Add a reward
    const totalLoyaltyPoint = (user.totalLoyaltyPoint ?? 0) + params.rewardPoint
    const userReward: Firestore.UserReward = {
      userRef: userSnapshot,
      userId: params.userId,
      key: 'manual',
      title: params.rewardTitle,
      point: params.rewardPoint,
      createdAt: Firebase.firestore.Timestamp.now()
    }
    try {
      await firestore
        .collection('users')
        .doc(params.userId)
        .collection('userRewards')
        .add(userReward)
      await firestore
        .collection('users')
        .doc(params.userId)
        .set({ totalLoyaltyPoint, loyaltyStatus: Loyalty.getStatus(totalLoyaltyPoint) }, { merge: true })
    } catch (e) {
      return false
    }
    return true
  },

  getStatusLabel: (loyaltyStatus?: Firestore.LoyaltyStatus): string => {
    switch (loyaltyStatus) {
      case LOYALTY_STATUS1:
        return 'ホワイト会員'
      case LOYALTY_STATUS2:
        return 'ゴールド会員'
      case LOYALTY_STATUS3:
        return 'プラチナ会員'
      case LOYALTY_STATUS4:
        return 'ダイヤモンド会員'
      default:
      return 'ホワイト会員'
    }
  },

  getDescriptions: async (): Promise<string> => {
    const loyaltyDoc = await firestore
      .collection('settings')
      .doc('loyalties')
      .get()
    if (!loyaltyDoc.exists) return ''
    const loyalty = loyaltyDoc.data() as Firestore.LoyaltySetting
    if (!loyalty) return ''

    return loyalty.descriptions
  },

  getLoyaltyStatuses: (): Firestore.LoyaltyStatus[] => {
    return [LOYALTY_STATUS1, LOYALTY_STATUS2, LOYALTY_STATUS3, LOYALTY_STATUS4]
  },
}
