import { Layout } from './Layout'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { firestore } from '@/lib/firebase'
import { Loyalty } from '@/lib/loyalty'
import download from '@/lib/download'

export const useProps = (): FCProps<typeof Layout> => {
  const [loyaltyStatus, setLoyaltyStatus] = useState<Firestore.LoyaltyStatus>()
  const [users, setUsers] = useState<Firestore.User[]>([])
  const [downloading, setDownloading] = useState<boolean>(false)
  const history = useHistory()

  const onPressDownload = async () => {
    setDownloading(true)
    const title = ['名前', '会社名', 'メールアドレス', '会員ステータス', 'ギャラステ'].join(',')

    let ref: firebase.firestore.Query<firebase.firestore.DocumentData> = firestore.collection('users')
    if (loyaltyStatus) {
      ref = ref.where('loyaltyStatus', '==', loyaltyStatus)
    }
    const snapshot = await ref.orderBy('totalLoyaltyPoint', 'desc').get()
    const contents = snapshot.docs.map(doc => {
      const data = doc.data() as Firestore.User
      return [
        `"${data.name}"` || '',
        `"${data.company_name}"` || '',
        `"${data.email_address}"`,
        Loyalty.getStatusLabel(data.loyaltyStatus),
        data.totalLoyaltyPoint || 0,
      ].join(',')
    }).join('\n')
    download('users.csv', title + '\n' + contents)
    setDownloading(false)
  }

  useEffect(() => {
    return firestore.collection('users')
      .limit(100)
      .onSnapshot(async a => {
        const p = await Promise.all(
          a.docs.map<Promise<Firestore.User>>(async b => {
            const data = b.data()
            return {
              ...data,
              id: b.id,
            } as Firestore.User
          }),
        )
        setUsers(p)
      })
  }, [])

  return {
    users,
    onUpdateLoyaltyStatus: setLoyaltyStatus,
    onPressDownload,
    onPressShow: async id => {
      history.push(`/users/${id}`)
    },
    downloading,
  }
}
