import React, { useState } from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import Loading from '@/components/Loading'
import { LayoutProps } from './props'
import { Layout, PageHeader } from 'antd'

const pageTitle = 'インフォメーション'

export default ({
  loading,
  informations,
  editInformationKey,
  onChangeEditInformationKey,
  onChangeSubmit,
}: LayoutProps) => (
  <Root title={pageTitle}>
    <Layout>
      <StyledPageHeader title={pageTitle} />
      <ContentBody>
        <Description>アプリのホームに表示される情報を編集できます</Description>
        {loading ? (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 1000,
              backgroundColor: 'rgba(255,255,255,0.7)',
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <Loading show />
          </div>
        ) : null}
        {informations.campaign ? (
          <EditableForm
            label="キャンペーン"
            url={informations.campaign.link}
            imageUrl={informations.campaign.image}
            mode={
              editInformationKey === 'campaign'
                ? 'edit'
                : editInformationKey === undefined
                ? 'view'
                : 'other_selected'
            }
            onPressEdit={() => onChangeEditInformationKey('campaign')}
            onPressEditCancel={() => onChangeEditInformationKey(undefined)}
            onPressEditSubmit={onChangeSubmit}
          />
        ) : null}
        {informations.lounge_information ? (
          <EditableForm
            label="施設情報"
            url={informations.lounge_information.link}
            imageUrl={informations.lounge_information.image}
            mode={
              editInformationKey === 'lounge_information'
                ? 'edit'
                : editInformationKey === undefined
                ? 'view'
                : 'other_selected'
            }
            onPressEdit={() => onChangeEditInformationKey('lounge_information')}
            onPressEditCancel={() => onChangeEditInformationKey(undefined)}
            onPressEditSubmit={onChangeSubmit}
          />
        ) : null}
        {informations.kirameki ? (
          <EditableForm
            label="ひらめき"
            url={informations.kirameki.link}
            imageUrl={informations.kirameki.image}
            mode={
              editInformationKey === 'kirameki'
                ? 'edit'
                : editInformationKey === undefined
                ? 'view'
                : 'other_selected'
            }
            onPressEdit={() => onChangeEditInformationKey('kirameki')}
            onPressEditCancel={() => onChangeEditInformationKey(undefined)}
            onPressEditSubmit={onChangeSubmit}
          />
        ) : null}
      </ContentBody>
    </Layout>
  </Root>
)

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
  position: relative;
`

const Description = styled.div`
  font-size: 14px;
  color: #aaa;
`

const Label = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #666;
`

const FormLabel = styled.label`
  font-size: 14px;
  color: #888;
  margin-right: 10px;
  margin-left: 30px;
`

const Form = styled.input`
  box-shadow: none;
  padding: 8px 10px;
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  background-color: white;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
`

const EditableForm = (props: {
  label: string
  url: string
  imageUrl: string
  mode: 'view' | 'edit' | 'other_selected'
  onPressEdit: () => void
  onPressEditCancel: () => void
  onPressEditSubmit: (url?: string, file?: File) => void
}) => {
  const [editUrl, setEditUrl] = useState('')
  const [eidtImage, setEditImage] = useState<File | undefined>()
  return (
    <div style={{ marginTop: 30, marginBottom: 60, opacity: props.mode === 'other_selected' ? 0.3 : 1 }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
        <Label>{props.label}</Label>
        {props.mode === 'edit' ? (
          <>
            <button
              style={{
                borderRadius: 8,
                border: 'none',
                padding: '2px 20px',
                backgroundColor: '#AAA',
                color: 'white',
                fontSize: 13,
                marginLeft: 10,
              }}
              onClick={props.onPressEditCancel}>
              キャンセル
            </button>
            <button
              style={{
                borderRadius: 8,
                border: 'none',
                padding: '2px 20px',
                backgroundColor: '#1f99ef',
                color: 'white',
                fontSize: 13,
                marginLeft: 10,
              }}
              onClick={() => props.onPressEditSubmit(editUrl, eidtImage)}>
              保存
            </button>
          </>
        ) : (
          <button
            style={{
              borderRadius: 8,
              border: 'none',
              padding: '2px 20px',
              backgroundColor: '#555',
              color: 'white',
              fontSize: 13,
              marginLeft: 10,
            }}
            disabled={props.mode === 'other_selected'}
            onClick={props.onPressEdit}>
            編集
          </button>
        )}
      </div>
      {props.mode === 'edit' ? (
        <>
          <div style={{ marginBottom: 20 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormLabel>新しいURL</FormLabel>
              <Form style={{ borderColor: '#AAA' }} onChange={event => setEditUrl(event.target.value)} />
            </div>
            <div style={{ height: 5 }} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <FormLabel>新しい画像</FormLabel>
              <Form
                type="file"
                style={{ borderColor: '#AAA' }}
                onChange={event => setEditImage(event.target.files?.[0])}
              />
            </div>
          </div>
          <div style={{ marginLeft: 30, fontSize: 16, fontWeight: 'bold', marginTop: 20 }}>現在の設定</div>
        </>
      ) : null}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel>URL</FormLabel>
        <Form disabled style={{ borderColor: 'white' }} value={props.url} />
      </div>
      <div style={{ height: 5 }} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormLabel>画像</FormLabel>
        <Form disabled style={{ borderColor: 'white' }} value={props.imageUrl} />
      </div>
      <img src={props.imageUrl} style={{ width: 300, marginLeft: 30, marginTop: 10 }} alt="現在の画像" />
    </div>
  )
}
