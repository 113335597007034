import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import { Layout, PageHeader } from 'antd'

const pageTitle = 'ダッシュボード'
const dataStudioUrl =
  'https://datastudio.google.com/embed/reporting/2bc494c3-a353-4d04-9afa-c5506b34f14b/page/Gg3'

export default () => (
  <Root title={pageTitle}>
    <Layout>
      <StyledPageHeader title={pageTitle} />

      <ContentBody>
        <iframe
          title="Dashboard"
          width="100%"
          height="1400px"
          src={dataStudioUrl}
          style={{ border: '0' }}
          allowFullScreen></iframe>
      </ContentBody>
    </Layout>
  </Root>
)

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
