import React from 'react'
import styled from 'styled-components'
import { Table, Button, Select } from 'antd'
import { Loyalty } from '@/lib/loyalty'

export type Props = {
  users: Firestore.User[]
  onUpdateLoyaltyStatus: (loyaltyStatus: Firestore.LoyaltyStatus) => void
  onPressDownload: () => void
  onPressShow: (id: App.User['id']) => void
  downloading: boolean
}

export const Layout = (props: Props) => {
  const { Option } = Select

  return (
    <ContentBody>
      会員ステータス
      <Select onChange={props.onUpdateLoyaltyStatus} style={{ width: 160, margin: '0 8px' }}>
        <>
          {Loyalty.getLoyaltyStatuses().map((loyaltyStatus) => {
            return <Option key={loyaltyStatus} value={loyaltyStatus}>{Loyalty.getStatusLabel(loyaltyStatus)}</Option>
          })}
        </>
      </Select>
      <Button type="primary" onClick={props.onPressDownload} loading={props.downloading}>ダウンロード</Button>

      <Table
        dataSource={props.users}
        columns={[
          {
            title: '名前',
            dataIndex: 'name',
            key: 'name',
            render: (name: Firestore.User['name']) => {
              return <div style={{ fontSize: 14 }}>{name}</div>
            },
          },
          {
            title: '会社名',
            dataIndex: 'company_name',
            key: 'company_name',
            render: (company_name: Firestore.User['company_name']) => {
              return <div style={{ fontSize: 14 }}>{company_name}</div>
            },
          },
          {
            title: 'メールアドレス',
            dataIndex: 'email_address',
            key: 'email_address',
            render: (email_address: Firestore.User['email_address']) => {
              return <div style={{ fontSize: 14 }}>{email_address}</div>
            },
          },
          {
            title: '会員ステータス',
            dataIndex: 'loyaltyStatus',
            key: 'loyaltyStatus',
            render: (loyaltyStatus: Firestore.User['loyaltyStatus']) => {
              return <div style={{ fontSize: 14 }}>{Loyalty.getStatusLabel(loyaltyStatus)}</div>
            },
          },
          {
            title: 'ギャラステ',
            dataIndex: 'totalLoyaltyPoint',
            key: 'totalLoyaltyPoint',
            render: (totalLoyaltyPoint: Firestore.User['totalLoyaltyPoint']) => {
              return <div style={{ fontSize: 14 }}>{totalLoyaltyPoint ?? 0}</div>
            },
          },
          {
            title: '',
            key: 'action',
            render: (user: Firestore.User) => {
              return (
                <>
                  <div style={{ display: 'flex' }}>
                    <StyledButton onClick={() => props.onPressShow(user.id)}>利用者詳細</StyledButton>
                  </div>
                </>
              )
            },
          },
        ]}
      />
    </ContentBody>
  )
}

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
const StyledButton = styled(Button)`
  margin: 0 3px;
  font-size: 12px;
`
