import { useInformations } from '@/hooks/useInformations'
import { useCallback, useState } from 'react'
import { LayoutProps } from './props'

export default (): LayoutProps => {
  const { loading, informations, upload } = useInformations()
  const [editInformationKey, setEditInformationkey] = useState<
    keyof LayoutProps['informations'] | undefined
  >()
  const [posting, setPosting] = useState(false)
  return {
    editInformationKey,
    loading: loading || posting,
    informations,
    onChangeEditInformationKey: useCallback(key => setEditInformationkey(key), []),
    onChangeSubmit: useCallback(
      (url, image) => {
        if (!editInformationKey) return
        if (!url || url.length === 0) return alert('URLを入力してください')
        if (!image) return alert('画像を選択してください')
        if (!image.type.startsWith('image/')) return alert('画像はjpeg, pngを選択してください')

        setPosting(true)
        upload({ key: editInformationKey, url, image }).then(result => {
          setPosting(false)
          if (result) {
            setEditInformationkey(undefined)
          } else {
            alert('更新に失敗しました')
          }
        })
      },
      [editInformationKey],
    ),
  }
}
