import Layout from './Layout'
import { useUser } from '@/hooks/useUser'
import { useLoyalties } from '@/hooks/useLoyalties'
import { useFullScreenLoading } from '@/context/FullScreenLoadingContext'
import { Loyalty } from '@/lib/loyalty'
import { message } from 'antd'
import { useEffect, useState } from 'react'
import { Api } from '@/lib/api'

type Params = {
  userId: Firestore.User['id']
}


export const useProps = (params: Params): FCProps<typeof Layout> => {
  const { user, userRewards } = useUser({ userId: params.userId })
  const { loyalties } = useLoyalties()
  const { setVisible } = useFullScreenLoading()
  const [visibleRewardForm, setVisibleRewardForm] = useState<boolean>(false)
  const [rewardTitle, setRewardTitle] = useState<Firestore.UserReward['title']>()
  const [rewardPoint, setRewardPoint] = useState<Firestore.UserReward['point']>()
  const [formText, setFormText] = useState<string>('')
  const [visibleTextForm, setVisibleTextForm] = useState<boolean>(false)

  useEffect(() => {
    user && setFormText(user.note || '')
  },[user])

  return {
    user,
    userRewards,
    loyalties,
    onPressLoyalty: async (loyalty: Firestore.Loyalty) => {
      if (window.confirm(`本当に${user?.name}さんに${loyalty.title}の報酬を付与しますか？`)) {
        if (!user) return
        setVisible(true)
        try {
          const result = await Loyalty.addReward({ userId: user.id, loyaltyId: loyalty.id})
          if (result) {
            message.success(`${user?.name}さんに${loyalty.title}の報酬を付与しました`)
          } else {
            message.error(`${user?.name}さんに${loyalty.title}の報酬を付与できませんでした`)
          }
        } catch (e) {
          message.error(`${user?.name}さんに${loyalty.title}の報酬を付与できませんでした`)
        } finally {
          setVisible(false)
        }
      }
    },
    visibleRewardForm,
    onPressVisibleReward: () => setVisibleRewardForm(true),
    onChangeRewardTitle: (title: Firestore.UserReward['title']) => setRewardTitle(title),
    onChangeRewardPoint: (point: Firestore.UserReward['point']) => setRewardPoint(point),
    onPressCancelReward: () => {
      setRewardTitle(undefined)
      setRewardPoint(undefined)
      setVisibleRewardForm(false)
    },
    onSubmitReward: async () => {
      if (!rewardTitle) return message.error('付与する理由は必須です。')
      if (!rewardPoint) return message.error('付与するポイントは必須です。')
      if (!user) return
      if (window.confirm(`本当に ${user?.name} さんに ${rewardTitle} で ${rewardPoint} Gの報酬を付与しますか？`)) {
        setVisible(true)
        try {
          const result = await Loyalty.addRewardManually({userId: user.id, rewardTitle, rewardPoint})
          if (result) {
            message.success(`${user.name} さんに ${rewardTitle} で ${rewardPoint} Gの報酬を付与しました`)
          } else {
            message.error(`${user.name} さんに ${rewardTitle} で ${rewardPoint} Gの報酬を付与できませんでした`)
          }
        } catch (e) {
          message.error(`${user.name} さんに ${rewardTitle} で ${rewardPoint} Gの報酬を付与できませんでした`)
        } finally {
          setVisibleRewardForm(false)
          setVisible(false)
        }
      }
    },
    formText,
    visibleTextForm,
    onPressEditText: () => setVisibleTextForm(true),
    onChangeText: (text: string) => setFormText(text),
    onPressCancelText: () => {
      user && setFormText(user.note || '')
      setVisibleTextForm(false)
    },
    onSubmitText: () => {
      if (!user) return message.error('変更に失敗しました。')
      setVisible(true)
      try {
        const result = Api.putUser({user: { id: user.id, note: formText }})
        if (result) {
          message.success('変更に成功しました。')
        } else {
          message.error('変更に失敗しました。')
        }
      } catch (e) {
        message.error('変更に失敗しました。')
      } finally {
        setVisibleTextForm(false)
        setVisible(false)
      }
    },
  }
}
