import { useRouteMatch } from 'react-router'
import { logout } from '@/lib/firebase'
import { Props, LayoutProps } from './props'

export default ({ title, children }: Props): LayoutProps => {
  document.title = `${title} | Garraway F`
  const { path: currentPath } = useRouteMatch()
  const selectedKey = currentSelectedKey(currentPath)

  return {
    selectedKey,
    logout,
    children,
  }
}

const currentSelectedKey = (path: string): string => {
  if (path.match(/^\/checkin_logs/) !== null) {
    return 'notifications'
  } else if (path.match(/^\/posts/) !== null) {
    return 'posts'
  } else if (path.match(/^\/events/) !== null) {
    return 'events'
  } else if (path.match(/^\/users/) !== null) {
    return 'users'
  } else if (path.match(/^\/startups/) !== null) {
    return 'startups'
  } else if (path.match(/^\/information/) !== null) {
    return 'information'
  } else if (path.match(/^\/loyalties/) !== null) {
    return 'loyalties'
  } else {
    return 'dashboard'
  }
}
