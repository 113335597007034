import React from 'react'
import styled from 'styled-components'
import { Table, Button } from 'antd'
import moment from 'moment'
import { useState, useEffect } from 'react'

type Props = {
  checkinLogs: Firestore.CheckinLog[]
  onPressShow: (id: App.User['id']) => void
}

export const Layout = (props: Props) => {
  const [entityCheckinLogs, setEntityCheckinLogs] = useState<Firestore.CheckinLog[]>([])

  useEffect(() => {
    const fetchEntityCheckinLogs = async () => {
      const p = await Promise.all(
        props.checkinLogs.map(async (checkinLog) => {
          if (!checkinLog.userEntity)
            checkinLog.userEntity = (await checkinLog.user.get()).data()
          return checkinLog
        })
      )
      setEntityCheckinLogs(p)
    }
    fetchEntityCheckinLogs()
  }, [props.checkinLogs])

  return (
    <ContentBody>
      <Table
        dataSource={entityCheckinLogs}
        columns={[
          {
            title: '来館日時',
            dataIndex: 'checkin_at',
            key: 'checkin_at',
            sorter: (a, b) => moment(a.checkin_at.toDate()).unix() - moment(b.checkin_at.toDate()).unix(),
            render: (checkin_at: Firestore.CheckinLog['checkin_at']) => {
              return <div style={{ fontSize: 14 }}>{checkin_at.toDate().toLocaleString('ja-JP')}</div>
            },
          },
          {
            title: '会社名',
            dataIndex: 'userEntity',
            key: 'company_name',
            render: (userEntity: Firestore.CheckinLog['userEntity']) => {
              return <div style={{ fontSize: 14 }}>{userEntity?.company_name}</div>
            },
          },
          {
            title: '名前',
            dataIndex: 'userEntity',
            key: 'name',
            render: (userEntity: Firestore.CheckinLog['userEntity']) => {
              return <div style={{ fontSize: 14 }}>{userEntity?.name}</div>
            },
          },
          {
            title: '',
            key: 'action',
            render: (checkinLog: Firestore.CheckinLog) => {
              return (
                <>
                  <div style={{ display: 'flex' }}>
                    <StyledButton onClick={() => props.onPressShow(checkinLog.user_uid)}>利用者詳細</StyledButton>
                  </div>
                </>
              )
            },
          },
        ]}
      />
    </ContentBody>
  )
}

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
const StyledButton = styled(Button)`
  margin: 0 3px;
  font-size: 12px;
`
