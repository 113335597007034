import React from 'react'
import styled from 'styled-components'
import { WithAuth as Root } from '@/components/Root'
import FacebookEvent from '@/components/FacebookEvent'
import { RoundedBorderButton } from '@/components/Button'
import Loading from '@/components/Loading'
import { LayoutProps } from './props'
import { Layout, PageHeader } from 'antd'

const pageTitle = 'イベント'

export default ({
  loading,
  thisWeekEvents,
  nextWeekEvents,
  afterNextWeekEvents,
  refreshing,
  events,
  refresh,
}: LayoutProps) => (
  <Root title={pageTitle}>
    <Layout>
      <StyledPageHeader title={pageTitle} />

      <ContentBody>
        <Button onClick={refresh}>{refreshing ? '...' : 'Facebook から最新情報を取得'}</Button>

        {!loading && (
          <>
            <WeelkyEvents>
              <WeelkyEvent>
                <WeelkyEventLabel>今週</WeelkyEventLabel>
                <WeelkyEventValue>{thisWeekEvents.length}</WeelkyEventValue>件
              </WeelkyEvent>
              <WeelkyEvent>
                <WeelkyEventLabel>来週</WeelkyEventLabel>
                <WeelkyEventValue>{nextWeekEvents.length}</WeelkyEventValue>件
              </WeelkyEvent>
              <WeelkyEvent>
                <WeelkyEventLabel>再来週</WeelkyEventLabel>
                <WeelkyEventValue>{afterNextWeekEvents.length}</WeelkyEventValue>件
              </WeelkyEvent>
            </WeelkyEvents>

            <div>
              {events.map(event => (
                <FacebookEvent key={event.id} event={event} />
              ))}
            </div>
          </>
        )}

        <Loading show={loading} />
      </ContentBody>
    </Layout>
  </Root>
)

const WeelkyEvents = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`

const WeelkyEvent = styled.div`
  text-align: center;
  border: 1px solid #ddd;
  display: block;
  width: 100%;
  padding: 1rem;
  background: #fff;
  color: #555;
  line-height: 1em;

  &:nth-child(2) {
    margin: 0 1rem;
  }
`

const WeelkyEventLabel = styled.div`
  font-size: 0.9rem;
  margin-bottom: 1rem;
  color: #555;
`

const WeelkyEventValue = styled.span`
  display: inline-block;
  font-size: 1.5rem;
  color: #121212;
  margin: 0 0.2rem;
`

const Button = styled(RoundedBorderButton)`
  margin: 0 0 2rem auto;
`

const StyledPageHeader = styled(PageHeader)`
  margin: 16px 0;
  background-color: #fff;
`

const ContentBody = styled.div`
  background: #fff;
  padding: 24px;
  min-height: 360px;
`
